.main-banner {
  background-color: #051927;
  padding: 12em 0 6em;
  position: relative;
  overflow: hidden;
}

.main-banner .caption {
  z-index: 10;
  position: relative;
}

.main-banner .caption .action {
  align-items: center;
  display: flex;
}

.main-banner .caption .action p {
  width: calc(100% - 50px);
  margin: 0;
  padding-right: 1em;
}

.main-banner .caption .action span {
  width: 50px;
  height: 50px;
  color: #fff;
  place-items: center;
  display: grid;
  position: relative;
}

.main-banner .caption .action span:before {
  content: "";
  width: 50px;
  height: 50px;
  border: 1px dashed #fff;
  border-radius: 50%;
  animation: 10s linear infinite spin;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.main-banner .caption .action span svg {
  transform: rotate(90deg);
}

.main-banner .cta-form {
  border-radius: var(--bs-border-radius-xl);
  background-color: #fff;
}

.main-banner .cta-form .inner {
  padding: 3em 2em;
}

.main-banner .cta-form .inner p {
  color: #ff8f29;
  font-family: Gotham Rounded Book, serif;
  font-size: 18px;
}

.main-banner .cta-form .inner p strong {
  font-family: Gotham Rounded Bold, serif;
}

.main-banner .cta-form .inner .form-group {
  margin-bottom: 1em;
}

.main-banner .cta-form .inner .form-group label {
  margin-bottom: .4em;
  font-family: Gotham Rounded Bold, serif;
  display: block;
}

.main-banner .cta-form .inner .submit button {
  width: 100%;
  justify-content: center;
  display: flex;
}

.main-banner .fig {
  z-index: 5;
  position: absolute;
  bottom: 0;
  left: 0;
}

.main-banner .fig img {
  width: 60px;
  height: 60px;
  object-fit: contain;
}

.main-banner .poster img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
  background-color: #051927;
  position: absolute;
  top: 0;
  left: 0;
}

@media (min-width: 992px) {
  .main-banner .caption .action span svg {
    transform: rotate(0);
  }
}

@media (min-width: 1200px) {
  .main-banner {
    padding: 12em 0 10em;
  }

  .main-banner .fig img {
    width: 100px;
    height: 100px;
  }
}

@keyframes spin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(359deg);
  }
}

.smile {
  z-index: 20;
  position: relative;
}

.smile img {
  width: 250px;
  height: auto;
  position: absolute;
  top: 0;
  right: 0;
  transform: translateX(15%)translateY(-50%)rotate(-25deg);
}

@media (min-width: 1200px) {
  .smile img {
    width: 400px;
  }
}

.solucion {
  position: relative;
}

.solucion .caption {
  z-index: 10;
  padding: 6em 0 0;
  position: relative;
}

.solucion .poster {
  margin-top: -20%;
  position: relative;
}

.solucion .poster:before {
  content: "";
  width: 100%;
  height: 60%;
  z-index: 5;
  background: linear-gradient(0deg, #fff0, #fff);
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.solucion .poster img {
  width: 100%;
  height: auto;
  object-fit: cover;
  aspect-ratio: 1 / 1;
}

@media (min-width: 576px) {
  .solucion .poster img {
    aspect-ratio: 4 / 3;
  }
}

@media (min-width: 768px) {
  .solucion .poster img {
    aspect-ratio: 16 / 9;
  }
}

@media (min-width: 992px) {
  .solucion .caption {
    padding: 6em 0;
  }

  .solucion .poster {
    width: 65%;
    height: 100%;
    margin-top: 0;
    position: absolute;
    top: 0;
    left: 0;
  }

  .solucion .poster:before {
    width: 60%;
    height: 100%;
    background: linear-gradient(90deg, #fff0, #fff);
    position: absolute;
    left: 40%;
  }

  .solucion .poster img {
    width: 100%;
    height: 100%;
    aspect-ratio: inherit;
  }
}

@media (min-width: 1200px) {
  .solucion .caption {
    padding: 8em 0;
  }
}

@media (min-width: 1400px) {
  .solucion .caption {
    padding: 10em 0;
  }
}

.proceso {
  background-color: #0f6ed6;
  padding: 6em 0;
}

.proceso ol {
  flex-flow: wrap;
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  position: relative;
}

.proceso ol li {
  width: 50%;
}

.proceso ol li figure {
  margin-bottom: 1em;
  padding: 0 1em;
}

.proceso ol li figure .icon {
  margin-bottom: .5em;
}

.proceso ol li figure figcaption {
  color: #fff;
  text-align: center;
  font-family: Gotham Rounded Bold, serif;
}

.proceso ol .wave-cont {
  display: none;
}

.proceso ol .wave-cont .proceso-wave {
  animation: 5s linear forwards lines;
}

@media (min-width: 992px) {
  .proceso ol li {
    width: 25%;
    z-index: 10;
    position: relative;
  }

  .proceso ol .wave-cont {
    z-index: 1;
    width: calc(100% - 8em);
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%)translateY(-50%);
  }
}

.dudas {
  padding: 6em 0 0;
  position: relative;
  overflow: hidden;
}

.dudas figure {
  width: calc(100% + 30px);
  position: relative;
  transform: translateX(-15px);
}

.dudas figure .fig {
  position: absolute;
  bottom: 1em;
  left: 1em;
}

.dudas figure .poster img {
  width: 100%;
  height: auto;
  aspect-ratio: 1 / 1;
  object-fit: cover;
}

.dudas .btn {
  background: #41ed7b;
  align-items: center;
  padding: .5em 1em;
  font-family: Gotham Rounded Bold, serif;
  display: inline-flex;
}

.dudas .btn svg {
  margin-left: .75em;
}

.dudas .btn:hover, .dudas .btn:focus {
  background: #16e55c;
}

@media (min-width: 768px) {
  .dudas figure {
    width: 100%;
    transform: translateX(0);
  }

  .dudas figure .poster img {
    aspect-ratio: 16 / 9;
  }
}

@media (min-width: 992px) {
  .dudas figure {
    width: calc(100% + 50px);
  }

  .dudas figure .fig {
    bottom: inherit;
    top: 0;
    left: 0;
    transform: translateX(-50%)translateY(-50%);
  }

  .dudas figure .poster img {
    aspect-ratio: 1 / 1;
  }
}

@media (min-width: 1200px) {
  .dudas figure .poster img {
    aspect-ratio: 4 / 3;
  }
}

.testimonios-block {
  background: radial-gradient(50% 50%, #ffd4ad 0%, #f7eee6 100%);
  padding: 6em 0;
  position: relative;
  overflow: hidden;
}

.testimonios-block .galeria figure img {
  aspect-ratio: 1 / 1;
  object-fit: cover;
}

@keyframes hovering {
  0% {
    transform: translateY(0%);
  }

  25% {
    transform: translateY(5%);
  }

  50% {
    transform: translateY(0%);
  }

  75% {
    transform: translateY(-5%);
  }

  100% {
    transform: translateY(0%);
  }
}

.cta-obten {
  z-index: 30;
  margin-top: -2em;
  position: relative;
}

.cta-obten .cont .container .inner {
  background: url("wave-bg.a7b8f6ee.png") bottom repeat-x;
  justify-content: center;
  align-items: center;
  padding: 1em;
  display: flex;
  box-shadow: 0 0 35px #00000026;
}

.cta-obten .cont .container .inner .txt {
  padding-right: 1em;
}

.cta-obten .cont .container .inner .txt p {
  color: #fff;
  margin: 0;
  font-family: Gotham Rounded Bold, serif;
  font-size: clamp(18px, 4vw, 24px);
}

.cta-obten .cont .container .inner .action .btn {
  color: #ff8f29;
  background-color: #fff;
  border: 1px solid #ff8f29;
}

.cta-obten .cont .container .inner .action .btn span {
  white-space: nowrap;
  word-break: keep-all;
}

.cta-obten .cont .container .inner .action .btn:hover, .cta-obten .cont .container .inner .action .btn:focus {
  color: #fff;
  background-color: #ff7a00;
  border-color: #fff;
}

.cta-obten .cont .container .inner .action .btn:active {
  color: #fff;
  border-color: #ff7a00;
}

@media (min-width: 768px) {
  .cta-obten .cont .container .inner .txt {
    border-right: 1px solid #ffffff4d;
    margin-right: 1em;
  }
}

@media (min-width: 992px) {
  .cta-obten {
    margin-top: 0;
  }

  .cta-obten .cont {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY(-50%);
  }

  .cta-obten .cont .container .inner .txt {
    margin-right: 2em;
    padding-right: 2em;
  }
}

@media (min-width: 1200px) {
  .cta-obten .cont .container .inner {
    padding: 2em;
  }

  .cta-obten .cont .container .inner .txt {
    margin-right: 2em;
    padding-right: 2em;
  }
}

@media (min-width: 1400px) {
  .cta-obten .cont .container .inner .txt {
    margin-right: 3em;
    padding-right: 3em;
  }
}

.ubicacion {
  padding: 6em 0;
  position: relative;
}

.ubicacion:after {
  content: "";
  width: 100%;
  height: calc(6em + 100px);
  background-color: #0f6ed6;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
}

.ubicacion .container {
  z-index: 10;
  position: relative;
}

.ubicacion .container .info .links {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.ubicacion .container .info .links li {
  border-bottom: 1px dashed #94aed040;
}

.ubicacion .container .info .links li a {
  color: #ff8f29;
  align-items: center;
  margin: 0;
  padding: .6em 0;
  text-decoration: none;
  display: flex;
}

.ubicacion .container .info .links li a span {
  color: #212122;
  margin-left: .65em;
  font-family: Gotham Rounded Bold, serif;
}

.ubicacion .container .info .links li a:hover span, .ubicacion .container .info .links li a:focus span {
  color: #0f6ed6;
}

.ubicacion .container .info .links li:last-child {
  border: none;
}

.ubicacion .container .mapa {
  margin-top: 50px;
}

.ubicacion .container .mapa iframe {
  width: 100%;
  height: 400px;
  margin: 0;
  padding: 0;
}

@media (min-width: 992px) {
  .ubicacion {
    padding-top: 8em;
  }

  .ubicacion:after {
    width: 30%;
    height: 100%;
    left: 70%;
  }

  .ubicacion .container .mapa iframe {
    height: 500px;
  }
}

@media (min-width: 1200px) {
  .ubicacion .container .mapa iframe {
    height: 550px;
  }
}

/*# sourceMappingURL=index.31daecb2.css.map */
