@import "variables";

.main-banner {
	background-color: #051927;
	position: relative;
	overflow: hidden;
	padding: 12em 0 6em;
	.caption {
		position: relative;
		z-index: 10;
		.action {
			display: flex;
			align-items: center;
			p {
				width: calc(100% - 50px);
				margin: 0;
				padding-right: 1em;
			}
			span {
				display: grid;
				place-items: center;
				width: 50px;
				height: 50px;
				position: relative;
				color: #fff;
				&::before {
					content: "";
					display: block;
					width: 50px;
					height: 50px;
					border-radius: 50%;
					border: 1px dashed #fff;
					position: absolute;
					top: 0;
					left: 0;
					animation: spin 10s linear infinite;
				}
				svg {
					transform: rotate(90deg);
				}
			}
		}
	}
	.cta-form {
		background-color: #fff;
		border-radius: var(--bs-border-radius-xl);
		.inner {
			padding: 3em 2em;
			p {
				font-family: $bookRndFont;
				font-size: 18px;
				color: $orange;
				strong {
					font-family: $boldRndFont;
				}
			}
			.form-group {
				margin-bottom: 1em;
				label {
					font-family: $boldRndFont;
					display: block;
					margin-bottom: 0.4em;
				}
			}
			.submit {
				button {
					width: 100%;
					display: flex;
					justify-content: center;
				}
			}
		}
	}
	.fig {
		position: absolute;
		bottom: 0;
		left: 0;
		z-index: 5;
		img {
			width: 60px;
			height: 60px;
			object-fit: contain;
		}
	}
	.poster {
		img {
			background-color: #051927;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
			z-index: 1;
		}
	}

	@media #{$break-md} {
		.caption {
			.action {
				span {
					svg {
						transform: rotate(0);
					}
				}
			}
		}
	}

	@media #{$break-lg} {
		padding: 12em 0 10em;
		.fig {
			img {
				width: 100px;
				height: 100px;
			}
		}
	}
}

@keyframes spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(359deg);
	}
}

.smile {
	position: relative;
	z-index: 20;
	img {
		position: absolute;
		top: 0;
		right: 0;
		transform: translateX(15%) translateY(-50%) rotate(-25deg);
		width: 250px;
		height: auto;
	}
	@media #{$break-lg} {
		img {
			width: 400px;
		}
	}
}

.solucion {
	position: relative;
	.caption {
		padding: 6em 0 0;
		position: relative;
		z-index: 10;
	}
	.poster {
		position: relative;
		margin-top: -20%;
		&::before {
			content: "";
			display: block;
			width: 100%;
			height: 60%;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 5;
			background: linear-gradient(
				0deg,
				rgba(255, 255, 255, 0),
				rgba(255, 255, 255, 1)
			);
		}
		img {
			width: 100%;
			height: auto;
			object-fit: cover;
			aspect-ratio: 1/1;
		}
	}

	@media #{$break-xs} {
		.poster {
			img {
				aspect-ratio: 4/3;
			}
		}
	}

	@media #{$break-sm} {
		.poster {
			img {
				aspect-ratio: 16/9;
			}
		}
	}

	@media #{$break-md} {
		.caption {
			padding: 6em 0;
		}
		.poster {
			position: absolute;
			top: 0;
			left: 0;
			width: 65%;
			height: 100%;
			margin-top: 0;
			&::before {
				position: absolute;
				width: 60%;
				height: 100%;
				left: 40%;
				background: linear-gradient(
					90deg,
					rgba(255, 255, 255, 0),
					rgba(255, 255, 255, 1)
				);
			}
			img {
				width: 100%;
				height: 100%;
				aspect-ratio: inherit;
			}
		}
	}

	@media #{$break-lg} {
		.caption {
			padding: 8em 0;
		}
	}

	@media #{$break-xl} {
		.caption {
			padding: 10em 0;
		}
	}
}

.proceso {
	background-color: $blue;
	padding: 6em 0;
	ol {
		list-style-type: none;
		margin: 0;
		padding: 0;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		position: relative;
		li {
			width: 50%;
			figure {
				margin-bottom: 1em;
				padding: 0 1em;
				.icon {
					margin-bottom: 0.5em;
				}
				figcaption {
					font-family: $boldRndFont;
					color: #fff;
					text-align: center;
				}
			}
		}
		.wave-cont {
			display: none;
			.proceso-wave {
				animation: lines 5s linear forwards;
			}
		}
	}

	@media #{$break-md} {
		ol {
			li {
				width: 25%;
				position: relative;
				z-index: 10;
			}
			.wave-cont {
				display: block;
				position: absolute;
				top: 50%;
				left: 50%;
				z-index: 1;
				width: calc(100% - 8em);
				transform: translateX(-50%) translateY(-50%);
			}
		}
	}
}

.dudas {
	padding: 6em 0 0 0;
	position: relative;
	overflow: hidden;
	figure {
		width: calc(100% + 30px);
		transform: translateX(-15px);
		position: relative;
		.fig {
			position: absolute;
			bottom: 1em;
			left: 1em;
		}
		.poster {
			img {
				width: 100%;
				height: auto;
				aspect-ratio: 1/1;
				object-fit: cover;
			}
		}
	}

	.btn {
		background: #41ed7b;
		padding: 0.5em 1em;
		font-family: $boldRndFont;
		display: inline-flex;
		align-items: center;
		svg {
			margin-left: 0.75em;
		}
		&:hover,
		&:focus {
			background: darken(#41ed7b, 10%);
		}
	}

	@media #{$break-sm} {
		figure {
			width: 100%;
			transform: translateX(0);
			.poster {
				img {
					aspect-ratio: 16/9;
				}
			}
		}
	}

	@media #{$break-md} {
		figure {
			width: calc(100% + 50px);
			.fig {
				bottom: inherit;
				left: 0;
				top: 0;
				transform: translateX(-50%) translateY(-50%);
			}
			.poster {
				img {
					aspect-ratio: 1/1;
				}
			}
		}
	}

	@media #{$break-lg} {
		figure {
			.poster {
				img {
					aspect-ratio: 4/3;
				}
			}
		}
	}
}

.testimonios-block {
	padding: 6em 0;
	position: relative;
	overflow: hidden;
	background: radial-gradient(50% 50% at 50% 50%, #ffd4ad 0%, #f7eee6 100%);
	.galeria {
		figure {
			img {
				aspect-ratio: 1/1;
				object-fit: cover;
			}
		}
	}
	/* .llaves-cont {
		position: relative;
		width: 100%;
		max-width: 396px;
		margin: auto;
		.chat {
			position: absolute;
			top: 0;
			right: 0;
			z-index: 20;
		}
		.llaves {
			position: relative;
			z-index: 10;
			img {
				width: 100%;
				height: auto;
				animation: hovering 5s linear infinite forwards;
			}
		}
		.sombra {
			position: absolute;
			bottom: 0;
			left: 0;
			img {
				width: 100%;
				height: auto;
			}
		}
	}
	.chats {
		list-style-type: none;
		margin: 0;
		padding: 0;
		position: relative;
		z-index: 10;
		margin-top: -50px;
		.item {
			margin-bottom: 2em;
			.chat {
				background-color: $blue;
				border-radius: var(--bs-border-radius-xl);
				position: relative;
				&::before,
				&::after {
					content: "";
					display: block;
					position: absolute;
					border-radius: 50%;
					background-color: $blue;
					bottom: 0;
					left: 0;
				}
				&::before {
					width: 30px;
					height: 30px;
					transform: translateY(5px);
				}
				&::after {
					width: 20px;
					height: 20px;
					transform: translateY(15px) translateX(15px);
				}
				.inner {
					padding: 1em;
					blockquote {
						margin: 0;
						padding: 0;
						color: #fff;
						p {
							font-family: $boldRndFont;
							font-size: 26px;
							font-size: clamp(18px, 4vw, 26px);
						}
						cite {
							font-family: $boldRndFont;
							color: $orange;
							font-style: normal;
							display: flex;
							align-items: center;
							&::before {
								content: "";
								display: inline-block;
								width: 20px;
								height: 2px;
								background-color: #fff;
								border-radius: 3px;
								margin-right: 0.5em;
							}
						}
					}
				}
			}
			&:nth-child(even) {
				.chat {
					background-color: $gray;
					&::before,
					&::after {
						background-color: $gray;
						left: inherit;
						right: 0;
					}
					&::after {
						transform: translateY(15px) translateX(-15px);
					}
					.inner {
						blockquote {
							cite {
								span {
									color: $blue;
								}
							}
						}
					}
				}
			}
		}
	}
	.chats-d {
		svg {
			img.keys {
				animation: hovering 5s linear infinite forwards;
			}
		}
	} */
}

@keyframes hovering {
	0% {
		transform: translateY(0%);
	}
	25% {
		transform: translateY(5%);
	}
	50% {
		transform: translateY(0%);
	}
	75% {
		transform: translateY(-5%);
	}
	100% {
		transform: translateY(0%);
	}
}

.cta-obten {
	position: relative;
	z-index: 30;
	margin-top: -2em;
	.cont {
		.container {
			.inner {
				background-color: $orange;
				background: url("../images/wave-bg.png") center bottom repeat-x;
				padding: 1em;
				display: flex;
				align-items: center;
				justify-content: center;
				box-shadow: 0 0 35px rgba(0, 0, 0, 0.15);
				.txt {
					padding-right: 1em;
					p {
						margin: 0;
						font-family: $boldRndFont;
						font-size: 18px;
						font-size: clamp(18px, 4vw, 24px);
						color: #fff;
					}
				}
				.action {
					.btn {
						color: $orange;
						background-color: #fff;
						border: 1px solid $orange;
						span {
							white-space: nowrap;
							word-break: keep-all;
						}
						&:hover,
						&:focus {
							background-color: darken($orange, 8%);
							border-color: #fff;
							color: #fff;
						}
						&:active {
							color: #fff;
							border-color: darken($orange, 8%);
						}
					}
				}
			}
		}
	}

	@media #{$break-sm} {
		.cont {
			.container {
				.inner {
					.txt {
						border-right: 1px solid rgba(255, 255, 255, 0.3);
						margin-right: 1em;
					}
				}
			}
		}
	}

	@media #{$break-md} {
		margin-top: 0;
		.cont {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			transform: translateY(-50%);
			.container {
				.inner {
					.txt {
						padding-right: 2em;
						margin-right: 2em;
					}
				}
			}
		}
	}

	@media #{$break-lg} {
		.cont {
			.container {
				.inner {
					padding: 2em;
					.txt {
						padding-right: 2em;
						margin-right: 2em;
					}
				}
			}
		}
	}

	@media #{$break-xl} {
		.cont {
			.container {
				.inner {
					.txt {
						padding-right: 3em;
						margin-right: 3em;
					}
				}
			}
		}
	}
}

.ubicacion {
	padding: 6em 0;
	position: relative;
	&::after {
		content: "";
		display: block;
		width: 100%;
		height: calc(6em + 100px);
		background-color: $blue;
		position: absolute;
		bottom: 0;
		left: 0;
	}
	.container {
		position: relative;
		z-index: 10;
		.info {
			.links {
				list-style-type: none;
				padding: 0;
				margin: 0;
				li {
					border-bottom: 1px dashed transparentize($gray, 0.75);
					a {
						display: flex;
						align-items: center;
						padding: 0.6em 0;
						margin: 0;
						text-decoration: none;
						color: $orange;
						span {
							font-family: $boldRndFont;
							color: $fontColor;
							margin-left: 0.65em;
						}
						&:hover,
						&:focus {
							span {
								color: $blue;
							}
						}
					}
					&:last-child {
						border: none;
					}
				}
			}
		}
		.mapa {
			margin-top: 50px;
			iframe {
				width: 100%;
				height: 400px;
				margin: 0;
				padding: 0;
			}
		}
	}

	@media #{$break-md} {
		padding-top: 8em;
		&::after {
			left: 70%;
			width: 30%;
			height: 100%;
		}
		.container {
			.mapa {
				iframe {
					height: 500px;
				}
			}
		}
	}

	@media #{$break-lg} {
		.container {
			.mapa {
				iframe {
					height: 550px;
				}
			}
		}
	}
}
